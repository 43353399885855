<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <vxe-modal ref="xModal" title="查看详情" width="1000" height="600" show-zoom>
    <template v-slot>
      <Details :currNode="currNode" :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import Details from "./Details.vue"
export default {
  components: {
    Details
  },
  data() {
    return {
      currNode: {},
      selectRow: null,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "inspectionPlanName",
            title: "计划名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入计划名称"
              }
            }
          },
          {
            field: "planUserName",
            title: "巡检人",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检人"
              }
            }
          },
          {
            field: "signType",
            title: "巡检方式",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "signType",
            },
          },
          {
            field: "planInsTime",
            title: "开始时间",
            width: 180,
            editRender: {
              name: "$input",
              props: {
                type: "datetime"
              }
            },
          },
          {
            field: "planEndTime",
            title: "结束时间",
            width: 180,
            editRender: {
              name: "$input",
              props: {
                type: "datetime"
              }
            },
          },
          {
            field: "state",
            title: "巡检状态",
            width: 180,
            editRender: {
              name: "$select",
              optionTypeCode: "inspectionStatus",
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 120,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                name: "detail",
                status: "primary",
                label: "详情",
                events: {
                  click: (row) => {
                    this.selectRow = row;
                    this.$refs.xModal.open();
                  }
                }
              }]
            }
          }
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "inspectionPlanName",
            title: "计划名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入计划名称"
              }
            }
          },
          // {
          //   field: "inspectionPlace",
          //   title: "巡检位置",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       maxlength: 24,
          //       placeholder: "请输入巡检位置"
          //     }
          //   }
          // },
          {
            field: "planUserName",
            title: "巡检人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检人"
              }
            }
          },
          {
            field: "signType",
            title: "巡检方式",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "signType",
              props: {
                placeholder: "请选择巡检方式"
              }
            }
          },
          {
            field: "state",
            title: "巡检状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "inspectionStatus",
              props: {
                placeholder: "请选择巡检状态"
              }
            }
          },
          {
            field: "planInsTime",
            title: "巡检时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date"
              }
            },
          },
          {
            field: "planEndTime",
            title: "结束时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date"
              }
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          inspectionName: [{ required: true, message: "请输入巡检名称" }],
          inspectionPlace: [{ required: true, message: "请输入巡检位置" }]
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          clientType: "1",
          content: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionPlanName",
            title: "计划名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入计划名称"
              }
            }
          },
          {
            field: "signType",
            title: "巡检方式",
            itemRender: {
              name: "$select",
              optionTypeCode: "signType",
              props: {
                placeholder: "请选择巡检方式"
              }
            }
          },
          {
            field: "state",
            title: "巡检状态",
            width: 180,
            itemRender: {
              name: "$select",
              optionTypeCode: "inspectionStatus",
              props: {
                placeholder: "请选择巡检状态"
              }
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          inspectionName: "",
          signType: "",
          state: "",
          inspectionPlanName: ''
        },
      },
      qrCodeUrl: "",
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
