<template>
<VxeBasicTable ref="xGrid" service="/wisdom/inspectionTaskDetail" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
</VxeBasicTable>
</template>

<script>
export default {
  props: ["currNode", "selectRow"],
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "inspectionName",
            title: "巡检点名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检点名称"
              }
            }
          },
          {
            field: "inspectionState",
            title: "巡检状态",
            width: 180,
            editRender: {
              name: "$select",
              optionTypeCode: "inspectionSign",
            },
          },
          {
            field: "actUserName",
            title: "巡检人",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检人"
              }
            }
          },
          {
            field: "inspectionTime",
            title: "签到时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入签到时间"
              }
            }
          },
          {
            field: "latitude",
            title: "精度",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入精度"
              }
            }
          },
          {
            field: "longitude",
            title: "纬度",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入维度"
              }
            }
          },
          {
            field: "inspectionPicture",
            title: "签到图片",
            minWidth: 160,
            editRender: {
              name: "$VxePreview",
              props: {
                maxlength: 24,
                placeholder: "请输入签到图片"
              }
            }
          },

           {
            field: "description",
            title: "描述",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入描述"
              }
            }
          },
          {
            field: "state",
            title: "巡检点状态",
            width: 180,
            editRender: {
              name: "$select",
              optionTypeCode: "inspectionOptionState",
            },
          },
          // inspectionPicture
          // {
          //   fixed: "right",
          //   title: "操作",
          //   width: 120,
          //   editRender: {
          //     enabled: true,
          //     name: "$VxeGridButtons",
          //     lists: [{
          //       name: "detail",
          //       status: "primary",
          //       label: "详情",
          //       events: {
          //         click: (row) => {
          //           let { items } = this.formOptions;
          //           let propertys = [];
          //           items.forEach(item => {
          //             propertys.push(item.field)
          //           });
          //           this.$Tools.enabledEvent(items, propertys, true)
          //           this.$refs.xGrid.queryEvent(row)
          //         }
          //       }
          //     }]
          //   }
          // }
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionName",
            title: "巡检点名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检点名称"
              }
            }
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          communityId: this.currNode.id,
          parentId: this.selectRow.id,
          inspectionName: ""
        },
      },
    };
  },

};
</script>

<style lang="scss" scoped>
</style>
